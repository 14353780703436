import React from "react";

import {
  StyledCarouselButton,
  BlogPostCard,
  H3,
  FluidImageGallery,
  BlogPostCardContent
} from "./StyledComponent";
import { ArrowRight } from "react-feather";

const BlogCard = ({ title, description, cover, link }) => {

  return (
    <BlogPostCard>
    {cover.map((el, i) => <FluidImageGallery
          key={i}
          maxWidth="100%"
          maxHeight="100%"
          fluid={el.image.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt={title}
        />)}
      <BlogPostCardContent>
        <H3 color="var(--secondary)">{title}</H3>
        <small>{description}</small>
        <StyledCarouselButton to={link}>
          <ArrowRight size={40} color="var(--white)" strokeWidth={1} />
        </StyledCarouselButton>
      </BlogPostCardContent>
    </BlogPostCard>
  );
};

export default BlogCard;
