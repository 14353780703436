import React from "react";
/** Importing functional components */
import DefaultPage from "../layout/DefaultPage";
import BlogCard from "./../components/BlogCard";
/** Importing styled components */
import {
  BlogPostLayout,
  GeneralContainer,
  PostRow,
  RootBorder
} from "../components/StyledComponent";

import { Col } from "react-flexbox-grid";

import { graphql } from "gatsby";

const BlogPage = ({ data: { getPostData } }) => {
  return (
    <DefaultPage pageTitle="Blog">
      <BlogPostLayout>
        <RootBorder />
        <GeneralContainer>
          <PostRow>
            {getPostData.edges.map(el => {
              return (
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  key={el.node.id}
                  style={{ marginTop: "1rem" }}
                >

                  <BlogCard
                    link={el.node.fields.slug}
                    title={el.node.frontmatter.title}
                    description={el.node.frontmatter.description}
                    cover={
                      el.node.frontmatter.cover
                    }
                  />
                </Col>
              );
            })}
          </PostRow>
        </GeneralContainer>
      </BlogPostLayout>
    </DefaultPage>
  );
};

export default BlogPage;

export const BlogPostQuery = graphql`
  query getPosts {
    getPostData: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "blog" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            cover {
              image {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
